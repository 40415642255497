import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdditionalFieldService } from '@app/additional-fields/additional-field.service';
import { AdditionalFieldsModule } from '@app/additional-fields/additional-fields.module';
import { UsersService } from '@app/users/users.service';
import { ModalsModule } from '@modals/modals.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AutomaticLogoutComponent } from '@public/automatic-logout/automatic-logout.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CompaniesService } from '../companies/companies.service';
import { SharedModule } from '../shared/shared.module';
import { AccessibilityDialogComponent } from './accessibility/accessibility.component';
import { SnowComponent } from './eastereggs/snow/snow.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExternalJwtRegistrationComponent } from './external/external-jwt-registration/external-jwt-registration.component';
import { RedirectComponent } from './external/redirect/redirect.component';
import { ForgotPasswordDDComponent } from './forgot-password-dd/forgot-password-dd.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginDeutscheDienstradComponent } from './login-dd/login-dd.component';
import { LoginSelectRoleDialogComponent } from './login-select-role-dialog/login-select-role-dialog.component';
import { LoginComponent } from './login/login.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceService } from './maintenance/maintenance.service';
import { MicrosoftLoginButtonComponent } from './microsoft-login-button/microsoft-login-button.component';
import { PublicFooterComponent } from './public-footer/public-footer.component';
import { RegistrationComponent } from './registration/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SimpleDialogBoxComponent } from '@public/simple-dialog-box/simple-dialog-box.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        NgSelectModule,
        ModalModule.forRoot(),
        TranslateModule,
        AdditionalFieldsModule,
        ModalsModule,
        MicrosoftLoginButtonComponent,
    ],
    declarations: [
        LoginComponent,
        LoginDeutscheDienstradComponent,
        MaintenanceComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegistrationComponent,
        ErrorPageComponent,
        RedirectComponent,
        ForgotPasswordDDComponent,
        SnowComponent,
        ExternalJwtRegistrationComponent,
        AutomaticLogoutComponent,
        LoginSelectRoleDialogComponent,
        AccessibilityDialogComponent,
        PublicFooterComponent,
        SimpleDialogBoxComponent,
    ],
    exports: [
        LoginComponent,
        LoginDeutscheDienstradComponent,
        MaintenanceComponent,
        ForgotPasswordComponent,
        ForgotPasswordDDComponent,
        ResetPasswordComponent,
        RegistrationComponent,
        ErrorPageComponent,
        SnowComponent,
        AutomaticLogoutComponent,
        AccessibilityDialogComponent,
        PublicFooterComponent,
        SimpleDialogBoxComponent,
    ],
    providers: [
        CompaniesService,
        MaintenanceService,
        UsersService,
        AdditionalFieldService,
    ],
})
export class PublicModule {}
