import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'dd-simple-dialog-box',
    templateUrl: './simple-dialog-box.component.html',
})
export class SimpleDialogBoxComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    public message: string;

    public ngOnInit(): void {
        this.message = this.route.snapshot.queryParamMap.get('message');
    }
}
