<header class="d-flex justify-content-start px-3">
    <div class="logo d-flex">
        <a
            class="d-flex"
            href="/"
        >
            <img
                class="logo-image p-2"
                src="./assets/images/logos/dd-logo.png"
                [alt]="'Logo - ' + ('GENERIC.ACTIONS.TO_LOGIN' | translate)"
            />
        </a>
    </div>
    <dd-header-title class="d-md-none"></dd-header-title>
</header>
