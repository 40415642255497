import {
    IServerSideGetRowsRequest,
    LoadSuccessParams,
} from '@ag-grid-community/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ExportSettings } from '@app/contracts/contracts.service';
import { FILENAME_MATCH, FILENAME_REPLACE1 } from '@core/constants/regex';
import { ApplicationError, ServerError } from '@core/exceptions/error';
import { saveAs } from '@core/helpers/file';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { ProfileService } from '@core/services/profile/profile.service';
import { MODULE } from '@core/tokens/module.token';
import { Factory } from '@shared/factory';
import { Order } from '@shared/models/order';
import { Page } from '@shared/models/page';
import { Status } from '@shared/models/status';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export interface OrderListRequest {
    pageSize: number;
    page: number;
    order_by?: string;
    order?: string;
    status_id?: number[];
    search?: string;
    user_id?: number;
}

export interface OrderFilterRequest {
    pageSize?: number;
    page?: number;
    order_by?: string;
    order?: string;
    order_id?: number;
    offer_id?: number;
    contract_id?: number;
    email?: string;
    status_id?: number;
    companyId?: number | string;
}

export interface SimpleOrderListRequest {
    status_id: number;
    user_id: number;
}

export interface PickupCodePayload {
    pickup_code: string;
    frame_number: string;
    card_issue_date: string;
    card_issue_authority: string;
    reference: string;
    battery_number: string;
}

export interface SendOrderPayload {
    delivery_planned: string;
    delivered: string;
    frame_number?: string;
    reference?: string;
    battery_number?: string;
}

export interface OrderToActivate {
    id: number;
    status_id: number;
    delivery_planned: Date;
}

@Injectable({ providedIn: 'root' })
export class OrdersService {
    private baseUrl = `${this.module}/orders`;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly toastrService: ToastrService,
        private profileService: ProfileService,
        @Inject(MODULE) private module: LoginMode,
    ) {}

    public update(id: number, order: Partial<Order>): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<ResponseGeneric<Order>>(`orders/${id}`, order, { headers })
            .pipe(
                map((response) => {
                    return new Factory(Order).fromObject(response.payload);
                }),
            );
    }

    public resendPickupCode(id: number): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post(`supplier/orders/${id}/resend-pickup-code`, {}, { headers })
            .pipe(
                map((response: { success: boolean }) => {
                    return response.success;
                }),
            );
    }

    public getFilteredOrders(
        request: OrderFilterRequest,
    ): Observable<Page<Order>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'orders/filter';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Page<Order>>(url, { params, headers }).pipe(
            catchError((error) => {
                const handledError = this.handleError(error);
                return throwError(() => handledError);
            }),
            map((response) => {
                const page = new Factory<Page<Order>>(Page).fromObject(
                    response,
                );
                page.data = new Factory(Order).fromArray(page.data);
                return page;
            }),
        );
    }

    public getPaginatedOrders(
        request: OrderListRequest,
    ): Observable<Page<Order>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'orders';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Page<Order>>(url, { params, headers }).pipe(
            catchError((error) => {
                const handledError = this.handleError(error);
                return throwError(() => handledError);
            }),
            map((response) => {
                const page = new Factory<Page<Order>>(Page).fromObject(
                    response,
                );
                page.data = new Factory(Order).fromArray(page.data);
                return page;
            }),
        );
    }

    public getStatuses(): Observable<Status[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'orders/statuses';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Status[]>(url, { headers }).pipe(
            map((res) => res),
            catchError((error) => throwError(() => error)),
        );
    }

    public get(id: string | number): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .get<Order>(`${this.baseUrl}/${id}`, { headers })
            .pipe(
                map((response) => new Factory(Order).fromObject(response)),
                catchError((error) => throwError(() => error)),
            );
    }

    public ready(id: number): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/${id}/ready`;
        return this.httpClient.post<Order>(url, null, { headers }).pipe();
    }

    public pickup(
        id: string | number,
        data: PickupCodePayload,
    ): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/${id}/pickup`;
        return this.httpClient.post<Order>(url, data, { headers }).pipe(
            tap(() =>
                this.toastrService.success(
                    'Sie haben die Abholung erfolgreich bestätigt',
                ),
            ),
            catchError(() => {
                this.toastrService.error(
                    'Die Abholung konnte nicht bestätigt werden',
                );
                return EMPTY;
            }),
        );
    }

    public validatePickup(
        orderId: number,
        pickupCode: string,
    ): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/${orderId}/validate-pickup`;
        return this.httpClient
            .post<boolean>(url, { pickup_code: pickupCode }, { headers })
            .pipe(catchError(() => of(false)));
    }

    public send(
        id: number,
        sentFormValue: SendOrderPayload,
    ): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/${id}/send`;
        return this.httpClient.post<Order>(url, sentFormValue, { headers });
    }

    public received(
        id: string | number,
        sentFormValue: unknown,
    ): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/${id}/received`;
        return this.httpClient
            .post<Order>(url, sentFormValue, { headers })
            .pipe(
                map((response) => new Factory(Order).fromObject(response)),
                catchError((error) => throwError(() => error)),
            );
    }

    public getSent(): Observable<number> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/sent`;
        return this.httpClient.get<number>(url, { headers }).pipe(
            map((res) => res),
            catchError((error) => throwError(() => error)),
        );
    }

    public uploadInvoice(
        id: string | number,
        file: unknown,
    ): Observable<Order> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post(
                `${this.baseUrl}/${id}/upload-invoice`,
                { invoice_file: file },
                { headers },
            )
            .pipe(
                map((response) => new Factory(Order).fromObject(response)),
                catchError((error) => throwError(() => error)),
            );
    }

    public downloadInvoice(id: string | number): void {
        this.httpClient
            .get(`${this.baseUrl}/${id}/download-invoice`, {
                responseType: 'blob',
                headers: {
                    Accept: 'application/json; charset=utf-8',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Skip-Module-Url': '',
                },
                observe: 'response',
            })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    return throwError(() => error);
                }),
            )
            .subscribe((res) => {
                const filename = res.headers
                    .get('content-disposition')
                    .match(FILENAME_MATCH)[1]
                    .replace(new RegExp(FILENAME_REPLACE1), '');
                const pdfObject = {
                    body: res.body,
                    filename,
                };
                saveAs(pdfObject.body, pdfObject.filename);
            });
    }

    public export(exportSettings: ExportSettings): Observable<boolean> {
        let url = 'orders/export';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post(
                url,
                { exportSettings: exportSettings },
                {
                    responseType: 'blob',
                    headers: {
                        Accept: 'application/json; charset=utf-8',
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-Skip-Module-Url': '',
                    },
                    observe: 'response',
                },
            )
            .pipe(
                tap((res: HttpResponse<Blob>) => {
                    const content = res.headers.get('content-disposition');
                    if (content) {
                        const filename = content
                            .match(FILENAME_MATCH)[1]
                            .replace(new RegExp(FILENAME_REPLACE1), '');
                        saveAs(res.body, filename);
                    }
                }),
                map(() => true),
                catchError(() => {
                    this.toastrService.error('Der Export ist fehlgeschlagen');
                    return of(false);
                }),
            );
    }

    public exportCreditNotes(): Observable<boolean> {
        const url = `system-api/export/leasings/credit-notes`;
        return new Observable((observer) => {
            this.httpClient
                .get(url, {
                    responseType: 'blob',
                    headers: {
                        Accept: 'application/json; charset=utf-8',
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-Skip-Module-Url': '',
                    },
                    observe: 'response',
                })
                .pipe(
                    catchError((error) => {
                        console.error(error);
                        observer.next(false);
                        observer.complete();
                        return throwError(() => error);
                    }),
                )
                .subscribe((res: HttpResponse<Blob>) => {
                    const content = res.headers.get('content-disposition');
                    if (content) {
                        const filename = content
                            .match(FILENAME_MATCH)[1]
                            .replace(new RegExp(FILENAME_REPLACE1), '');
                        saveAs(res.body, filename);
                    }
                    observer.next(true);
                    observer.complete();
                });
        });
    }

    public cancel(
        id: string | number,
        cancellationData: {
            cancellationKey: string;
            alternativeOfferExists: boolean;
        },
    ): Observable<Order> {
        const url = 'system/orders/' + id + '/cancel';
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<Order>(url, cancellationData, { headers })
            .pipe(map((order) => new Factory(Order).fromObject(order)));
    }

    private transformRequestToQuery(
        request: OrderListRequest | SimpleOrderListRequest | OrderFilterRequest,
    ): { [key: string]: string } {
        const transformedObject: { [p: string]: string } = {};
        Object.keys(request).forEach((key) => {
            if (request[key]) {
                transformedObject[key] = request[key].toString();
            }
        });
        transformedObject['global_view'] = String(
            this.profileService.global_view,
        );
        return transformedObject;
    }

    private handleError(response: HttpErrorResponse): ApplicationError {
        if (response instanceof HttpErrorResponse) {
            return new ServerError();
        } else {
            return response;
        }
    }

    public getAgGridData(
        request: IServerSideGetRowsRequest,
    ): Observable<LoadSuccessParams> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const params = new HttpParams({
            fromObject: {
                globalView: String(this.profileService.global_view),
            },
        });
        let url = 'orders/aggrid';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post<LoadSuccessParams>(url, request, { params, headers })
            .pipe(
                catchError((error) => {
                    const handledError = this.handleError(error);
                    return throwError(() => handledError);
                }),
            );
    }

    public getOrdersAboutToBeActivated(): Observable<OrderToActivate> {
        const url = 'employee/orders/about-to-activate';
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        return this.httpClient.get<OrderToActivate>(url, {
            headers,
        });
    }
}
