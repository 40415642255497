import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '@app/additional-fields/enums/field-type';
import { duplicateAttributeValidator } from '@app/additional-fields/validators/duplicate-attribute.validator';
import { NUMBER } from '@core/constants/regex';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { Company } from '@shared/models/company';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AdditionalField } from './interfaces/additional-field';
import { ValueExists } from './interfaces/value-exists';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import { nameInEmailValidator } from '@core/validators/name-in-email.validator';

@Injectable()
export class AdditionalFieldService {
    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
    ) {}

    public create(field: AdditionalField): Observable<AdditionalField> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http
            .post<AdditionalField>('additional-fields', field, {
                headers: headers,
            })
            .pipe(
                tap(() =>
                    this.toastrService.success(
                        'Zusätzliches Registrierungsfeld wurde hinzugefügt',
                    ),
                ),
                catchError((error) => {
                    this.toastrService.error(
                        'Beim Erstellen des zusätzlichen Registrierungsfelds ist etwas schief gelaufen',
                    );
                    return throwError(() => error);
                }),
            );
    }

    public update(field: AdditionalField): Observable<AdditionalField> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http
            .post<AdditionalField>(`additional-fields/${field.id}`, field, {
                headers: headers,
            })
            .pipe(
                tap(() =>
                    this.toastrService.success(
                        'Zusätzliches Registrierungsfeld wurde aktualisiert',
                    ),
                ),
                catchError((error) => {
                    this.toastrService.error(
                        'Beim Aktualisieren des zusätzlichen Registrierungsfelds ist etwas schief gelaufen',
                    );
                    return throwError(() => error);
                }),
            );
    }

    public valueExists(
        key: string,
        valueExists: ValueExists,
    ): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http.post<boolean>(
            `additional-fields/value-exists/${key}`,
            valueExists,
            { headers: headers },
        );
    }

    public conditionAlreadySet(
        key: string,
        userGroupId: number,
        value: string,
    ): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http.post<boolean>(
            `user-groups/additional-fields/check-duplicate`,
            { key: key, userGroupId: userGroupId, value: value },
            { headers: headers },
        );
    }

    public delete(field: AdditionalField): Observable<boolean> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.http
            .delete<boolean>(`additional-fields/${field.id}`, {
                headers: headers,
            })
            .pipe(
                tap(() =>
                    this.toastrService.success(
                        'Zusätzliches Registrierungsfeld wurde gelöscht',
                    ),
                ),
                catchError((error) => {
                    this.toastrService.error(
                        'Beim Löschen des zusätzlichen Registrierungsfelds ist etwas schief gelaufen',
                    );
                    return throwError(() => error);
                }),
            );
    }

    public populateForm(
        company: Pick<Company, 'slug' | 'additional_fields'>,
        form: FormGroup,
        userId?: number,
        disabled = false,
    ): void {
        company.additional_fields.forEach((field) => {
            const control = new FormControl({ value: '', disabled });

            if (field.key.includes('company_email')) {
                const splitted = field.key.split('-');

                control.addValidators([
                    CustomEmailValidator,
                    nameInEmailValidator(splitted[splitted.length - 1]),
                ]);
            }

            if (field.type === FieldType.NUMBER) {
                control.addValidators(Validators.pattern(NUMBER));
            }

            if (field.type === FieldType.EMAIL) {
                control.addValidators(CustomEmailValidator);
            }

            if (field.minLength) {
                control.addValidators(Validators.minLength(field.minLength));
            }

            if (field.maxLength) {
                control.addValidators(Validators.maxLength(field.maxLength));
            }

            if (field.isUnique) {
                control.addAsyncValidators(
                    duplicateAttributeValidator(
                        this,
                        field.key,
                        company.slug,
                        userId,
                    ),
                );
            }

            if (field.required) {
                control.addValidators(Validators.required);
            }

            form.addControl(field.key, control);
        });
    }
}
