import { UserEmailCategory } from '@app/users/interfaces/user-email-category.interface';
import { Company } from './company';
import { Model } from './model';
import { PermissionModel } from './permission.model';
import { Subsidies } from './subsidy';
import { PermissionType } from './user';

export class Profile extends Model {
    public max_user_contracts: number;
    public max_user_amount: number;
    public max_user_amount_text: string;
    public first_name: string;
    public last_name: string;
    public fullName: string;
    public company: Company;
    public active_company: Company;
    public email: string;
    public emailCategories: UserEmailCategory[] = [];
    public salutation: string;
    public street: string;
    public phone: string;
    public phoneprefix: string;
    public city: string;
    public country: string;
    public countryCode: string;
    public latitude?: number;
    public longitude?: number;
    public employee_number: string;
    public cost_centre: string;
    public postal_code: string;
    public postal_code_center: string;
    public password: string;
    public password_confirmation: string;
    public roles: any[] = [];
    public permissions: { id: number; name: string }[] = [];
    public portalId: number;
    public apartment_workplace_distance: number;
    public is_password_expired: boolean;
    public subsidies: Subsidies;
    public supplier: {
        id: number;
        name: string;
    };
    public company_policy: boolean;
    public active_role: { roleable_name: string; roleable_id: number };
    public activePermissions: PermissionModel[] = [];
    public automanage_offers: boolean;
    public origin: string;
    public calculator: boolean;
    public language: string;
    public isSsoUser: boolean;

    public max_conversion_amount: number;
    public calculated_max_user_amount: number;
    public enable_commercial_emails: boolean;
    public is_commercial_emailing_verified: boolean;

    public version: string;

    public externalUserId: string;
    public subsidyMode?: string;

    constructor(profile?: Profile) {
        super();
        if (profile) {
            this.id = profile.id;
            this.portalId = profile.portalId;
            this.company = profile.company;
            this.active_company = profile.active_company;
            this.first_name = profile.first_name;
            this.fullName = profile.fullName;
            this.last_name = profile.last_name;
            this.email = profile.email;
            this.emailCategories = profile.emailCategories;
            this.salutation = profile.salutation;
            this.phone = profile.phone;
            this.phoneprefix = profile.phoneprefix;
            this.street = profile.street;
            this.city = profile.city;
            this.country = profile.country;
            this.countryCode = profile.countryCode;
            this.postal_code = profile.postal_code;
            this.postal_code_center = profile.postal_code_center;
            this.latitude = profile.latitude;
            this.longitude = profile.longitude;
            this.employee_number = profile.employee_number;
            this.cost_centre = profile.cost_centre;
            this.roles = profile.roles;
            this.permissions = profile.permissions;
            this.apartment_workplace_distance =
                profile.apartment_workplace_distance;
            this.max_user_contracts = profile.max_user_contracts;
            this.max_user_amount = profile.max_user_amount;
            this.max_user_amount_text = profile.max_user_amount_text;
            this.is_password_expired = profile.is_password_expired;
            this.subsidies = profile.subsidies;
            this.supplier = profile.supplier;
            this.company_policy = profile.company_policy;
            this.active_role = profile.active_role;
            this.activePermissions = profile.activePermissions;
            this.automanage_offers = profile.automanage_offers;
            this.origin = profile.origin;
            this.calculator = profile.calculator;
            this.isSsoUser = profile.isSsoUser;
            this.max_conversion_amount = profile.max_conversion_amount;
            this.calculated_max_user_amount =
                profile.calculated_max_user_amount;
            this.enable_commercial_emails = profile.enable_commercial_emails;
            this.is_commercial_emailing_verified =
                profile.is_commercial_emailing_verified;
            this.version = profile.version;
            this.externalUserId = profile.externalUserId;
            this.subsidyMode = profile.subsidyMode;
        }
    }

    public isCompanyAdmin(): boolean {
        return this.hasRole('Company Admin');
    }

    public isEmployee(): boolean {
        return this.hasRole('Employee');
    }

    public isSupplier(): boolean {
        return this.hasRole('Supplier Admin');
    }

    public isPortalAdmin(): boolean {
        return this.hasRole('Portal Admin');
    }

    public hasRole($role: string): boolean {
        return (
            this.roles &&
            this.roles.filter(
                (item) =>
                    item.name == $role &&
                    item.roleable_id == this.active_role.roleable_id,
            ).length > 0 &&
            this.active_role != undefined &&
            this.active_role.roleable_name == $role
        );
    }

    public isSystemAdmin(): boolean {
        return !!this.roles?.find((item) => item === 'Systemadministrator');
    }

    public isEmployeeAdministrator(): boolean {
        return this.isCompanyAdmin() && this.isEmployee();
    }

    public hasPermission(permissionName: string): boolean {
        if (!this.permissions || this.permissions.length === 0) {
            return this.isSystemAdmin();
        }

        return (
            this.permissions.findIndex(
                (permission: PermissionModel) =>
                    permission.name === permissionName,
            ) !== -1
        );
    }

    public hasPermissionInActiveRole(permissionName: string): boolean {
        if (!this.permissions || this.permissions.length === 0) {
            return this.isSystemAdmin();
        }
        return (
            this.activePermissions.findIndex(
                (permission: PermissionModel) =>
                    permission.name === permissionName,
            ) !== -1
        );
    }

    public canManageCompanyEmployees(): boolean {
        return (
            this.hasPermissionInActiveRole(
                PermissionType.ManageCompanyEmployees,
            ) && this.company.enable_employee_registration
        );
    }

    public canEditCompanyData(): boolean {
        return this.hasPermissionInActiveRole(PermissionType.EditCompany);
    }
}
