import { FieldAttribute } from '@app/additional-fields/interfaces/field-attribute';
import { CoverageType } from '@app/individual-settings/enums/coverage-type';
import { IndividualSettings } from '@app/individual-settings/interfaces/individual-settings.interface';
import { UserGroup } from '@app/user-groups/interfaces/user-group.interface';
import { UserRole } from '@app/users/interfaces/user-role.interface';
import { HasMany } from '@core/relations/has-many';
import { HasOne } from '@core/relations/has-one';
import { Audit } from './audit';
import { Company } from './company';
import { Model } from './model';
import { PermissionModel } from './permission.model';
import { Supplier } from './supplier';
import { UserRoleInfo } from './user-role-info';

export enum SubsidyTypes {
    FIXED = 'fixed',
    PERCENTAGE = 'percentage',
}

export enum UserStatus {
    Active = 1,
    Inactive = 2,
    Pending = 17,
    RejectedPolicy = 37,
}

export enum PermissionType {
    Read = 'Read',
    Write = 'Write',
    ManageCompanyEmployees = 'Manage Company Employees',
    EditPortal = 'Edit Portal Data',
    EditCompany = 'Edit Company Data',
    Clerk = 'Clerk',
    ManageUserGroups = 'Manage User Groups',
    MainAdministrator = 'Main Administrator',
    ManageContractReplacements = 'Manage Contract Replacements',
    UploadPositiveList = 'Upload Positive List',
}

@HasMany(PermissionModel, 'permissions')
@HasOne(UserRoleInfo, 'roleInfo')
export class User extends Model implements IndividualSettings {
    public endpoint = 'users';
    public active_contracts: number;
    public active_from: string;
    public code?: string;
    public avatar: string;
    public company: Company;
    public email: string;
    public first_name: string;
    public id: number;
    public last_name: string;
    public status: any;
    public permissions: PermissionModel[];
    public street: string;
    public postal_code: string;
    public phoneprefix = '0';
    public phone: string;
    public employee_number: string;
    public cost_centre: string;
    public salutation: string;
    public city: string;
    public country: string;
    public countryCode: string;
    public roles: UserRole[] = [];
    public individual_settings = false;
    public insurance_rate_subsidy: boolean;
    public insurance_rate_subsidy_type: CoverageType;
    public insurance_rate_subsidy_amount: number;
    public service_rate_subsidy: boolean;
    public service_rate_subsidy_type: CoverageType;
    public service_rate_subsidy_amount: number;
    public leasing_rate_subsidy: boolean;
    public leasing_rate_subsidy_type: CoverageType;
    public leasing_rate_subsidy_amount: number;
    public max_user_contracts: number;
    public max_user_amount: number;
    public min_user_amount: number;
    public current_contracts: number;
    public current_offers: number;
    public current_orders: number;
    public created_at;
    public is_accept_offer = false;
    public audits: Audit[];
    public is_system: boolean;
    public roleInfo: UserRoleInfo;
    public is_child_admin: boolean;
    public supplier: Supplier;
    public supplier_id: number;
    public childs: User[];
    public hasChilds: boolean;
    public reverse_name: string;
    public apartment_workplace_distance: number;
    public active_role: { roleable_name; roleable_id };
    public company_policy: boolean;
    public role: string;
    public rejected_reason: string;
    public name: string;
    public attributes: FieldAttribute[] = [];
    public userGroup: UserGroup;
    public selected?: boolean;
    public type?: string;
    public additional_info?: string;
    private _fullName: string;
    public max_conversion_amount: number;
    public calculated_max_user_amount_min_service: number;
    public calculated_max_user_amount_max_service: number;
    public bankId: number;
    public is_anonymized: boolean;
    public cityName: string;
    public subsidyMode: string;
    public insuranceRateSubsidySecondBike?: boolean;
    public insuranceRateSubsidyTypeSecondBike?: CoverageType;
    public insuranceRateSubsidyAmountSecondBike?: number;
    public serviceRateSubsidySecondBike?: boolean;
    public serviceRateSubsidyTypeSecondBike?: CoverageType;
    public serviceRateSubsidyAmountSecondBike?: number;
    public leasingRateSubsidySecondBike?: boolean;
    public leasingRateSubsidyTypeSecondBike?: CoverageType;
    public leasingRateSubsidyAmountSecondBike?: number;
    public employer_pedelec_approval_value: boolean | null;

    public get fullName(): string {
        if (this._fullName) {
            return this._fullName;
        }
        return `${this.first_name} ${this.last_name}`;
    }

    public set fullName(value: string) {
        this._fullName = value;
    }
}
